.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  height: 95vh;
  padding: 2.5vh;
  padding-inline: 25vw;
}

.register-input {
  margin: 1vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
}

.register-input::placeholder {
  color: #515151;
}

.register-input:focus {
  outline: none;
  background: #080e20;
}

.error-message {
  color: rgb(154, 0, 0);
  font-size: 1.2rem;
  margin: 1vh 0;
}

.register-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 1vh 0;
}
