.band-discover-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.band-discover-body {
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.band-discover-bands {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 95%;
  margin: 1%;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.band-discover-tags {
  display: flex;
  margin-top: 1%;
}

.band-discover-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1%;
}
