.reset-page {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 93vh;
  padding: auto;
  margin: 0 auto;
  width: 90vw;
}

.reset-page h1 {
  font-size: 2.1rem;
}

.reset-page input {
  margin: 0.5vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
  width: 30%;
}

.reset-page input::placeholder {
  color: #515151;
}

.reset-page input:focus {
  outline: none;
  background: #080e20;
}

.reset-page button {
  margin: 2vh 0;
  font-size: 1.2rem;
  cursor: pointer;
}

.reset-page p {
  color: rgb(154, 0, 0);
  font-size: 1.2rem;
}
