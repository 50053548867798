.following-user-thumb {
  display: flex;
  align-items: center;
  padding: 5%;
}

.following-user-thumb-image {
  width: 25%;
  min-width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}

.following-user-thumb-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.following-user-name {
  display: flex;
  align-items: start;
  justify-content: left;
  width: 70%;
  padding: 2%;
  font-size: 1.2rem;
}
