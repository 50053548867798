.booking-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  transition: 0.3s;
  margin-top: 10vh;
  width: 100%;
  overflow-y: hidden;
}

.booking-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  width: 87%;
  height: 87%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 3%;
}

.booking-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 50%;
  width: 50%;
  padding: 5%;
}

.booking-image-wrapper {
  height: 90%;
  max-width: 50%;
  position: relative;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-image {
  border-radius: 1%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-page input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
}

.booking-page input:focus {
  outline: none;
}

.booking-page input::placeholder {
  color: #515151;
}

.booking-total {
  font-weight: bold;
}

.booking-terms {
  font-size: 0.6rem;
  margin-top: 2%;
}

.booking-terms a {
  color: #ed46c7;
  text-decoration: none;
}

label {
  font-weight: bold;
  font-size: 1.2rem;
}

.input-wrapper {
  justify-content: center;
  width: 100%;
}

.booking-button {
  background-color: transparent;
  color: white;
  border: solid 2px #4151b8;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
  display: initial;
}

.booking-button:hover {
  background-color: #4151b8;
}

.booking-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.close-button {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.close-button a {
  color: white;
  text-decoration: none;
  font-weight: 900;
}

.calendar {
  background-color: #080e20 !important;
  color: white !important;
  border: #ed46c7 1px solid !important;
  width: 100%;

  .react-datepicker__header {
    background-color: #080e20 !important;
    color: white !important;
  }

  .react-datepicker__current-month {
    color: white;
  }

  .react-datepicker__day-name {
    color: white;
  }

  .react-datepicker__day--selected {
    background-color: #ed46c7;
  }

  .react-datepicker__day {
    color: white;
  }

  .react-datepicker__day--disabled {
    color: #515151;
  }

  .react-datepicker__day:hover {
    background-color: #ed46c7;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #4151b8;
  }
}
