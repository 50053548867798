.terms {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  width: 90%;
  margin: 0 auto;
  margin-top: 4%;
  padding: 20px;
  text-align: left;
}

p {
  margin: 10px;
}
