.band-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;
  transition: 0.3s;
  margin-top: 7vh;
  width: 100%;
}

.band-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  width: 80%;
  padding: 30px;
}

.band-wrapper .image-container {
  height: 50%;
  max-width: 50%;
  position: relative;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.band-image {
  border-radius: 1%;
  height: 100%;
  position: relative;
  aspect-ratio: 1/1;
}

.band-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;
  padding: 10px;
}

.band-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.band-header {
  font-size: 2.5rem;
  font-weight: bold;
  background: linear-gradient(270deg, #ed46c7 35%, #4151b8 100%);
  color: #fff;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
}

.band-subheader {
  font-size: 1.5rem;
  padding-block: 10px;
}

.band-description {
  font-size: 1.1rem;
  text-align: left;
  padding-bottom: 10px;
}
