.convo-thumbnail {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  max-height: 10%;
}

.convo-thumbnail-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 10px;
}

.convo-header {
  display: flex;
}

.convo-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ed46c7;
  margin-right: 5%;
  margin-top: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.convo-thumbnail-date {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-top: 3%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.convo-thumbnail-secondary-text {
  text-align: left;
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.convo-thumbnail-text {
  justify-content: center;
  align-items: center;
}

.selected {
  background-color: #ed46c7;
  cursor: pointer;
}

.selected .convo-title {
  color: #e0e0e0;
}

.convo-thumbnail-description {
  font-weight: 700;
}
