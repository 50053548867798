.following-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.following-header {
  margin-top: 10vh;
  margin-bottom: 20px;
}

.following-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
}

.following-body {
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.following-users {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 95%;
  margin: 1%;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.following-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 95%;
  border-radius: 15px;
}

.following-events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.following-event {
  width: 95%;
  height: 40%;
}
