.results-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.results-header {
  height: 5vh;
  color: #ed46c7;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  top: 15%;
}

.results-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  height: 80%;
  margin-top: 2.5%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.results-card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no-results {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 17%;
}
