.footer-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #ed46c7;
  width: 100%;
  height: 50px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin: 0 auto;
}

.footer-bar a {
  color: white;
  text-decoration: none;
}
