.login-page {
  height: 100vh;
  transition: 0.3s;
  width: 90vw;
  margin: 0 auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  height: 95vh;
  padding: 2.5vh;
  padding-inline: 25vw;
}

.login-form-label {
  font-weight: bold;
  display: flex;
  justify-content: left;
  font-size: 1.2rem;
}

.login-form-input {
  margin: 1vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
}

.login-form-input::placeholder {
  color: #515151;
}

.login-form-input:focus {
  outline: none;
  background: #080e20;
}

.error-message {
  color: rgb(154, 0, 0);
  font-size: 1.2rem;
  margin: 1vh 0;
}

.google-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.google-button-text {
  margin-inline-start: 5px;
}
