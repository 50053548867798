.inbox-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
}

.inbox {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #080e20;
  width: 20vw;
  border-right: 1px solid #ed46c7;
}

.inbox-header {
  margin-top: 20%;
  display: flex;
  align-items: start;
}

.inbox-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ed46c7;
  margin-left: 2%;
  margin-bottom: 5%;
}
