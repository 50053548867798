@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200..1000&display=swap");

.App {
  text-align: center;
  font-family: "Mulish", sans-serif;
  background-color: #080e20;
  margin: 0px;
  padding: 0px;
  color: white;
  background-image: url("./assets/home-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
  height: 100vh;
  text-decoration: none;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #080e20;
}

*::-webkit-scrollbar-track:hover {
  background-color: #080e20;
}

*::-webkit-scrollbar-track:active {
  background-color: #080e20;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ed46c7;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ed46c7;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ed46c7;
}
