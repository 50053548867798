button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 1rem;
  background-color: #080e20;
  color: #ed46c7;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: #ed46c7 solid 3px;
}

button:hover {
  background-color: #ed46c7;
  color: #fff;
  transition: all 0.3s ease;
}
