.band-registration {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 51px);
  overflow-y: auto;
}

.band-registration-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 200px;
  height: 90vh;
  margin-top: 15vh;
  width: 35%;
}

.band-registration-form-label {
  font-weight: bold;
  display: flex;
  justify-content: left;
  font-size: 1.2rem;
}

.band-registration-form-input {
  margin: 1vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
  width: 100%;
}

.band-registration-form-input::placeholder {
  color: #515151;
}

.band-registration-form-input:focus {
  outline: none;
  background: #080e20;
}

.error-message {
  color: rgb(154, 0, 0);
  font-size: 1.2rem;
  margin: 1vh 0;
}

.band-registration-form-detail {
  color: #ed46c7;
}

#description {
  padding-bottom: 5em;
  font-family: "Mulish", sans-serif;
  resize: none;
}

.band-registration input {
  margin: 1vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
  width: 100%;
  min-height: 3%;
}

.band-registration div[role="combobox"] {
  margin: 1vh 0;
  padding-inline: 1vh;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.band-registration div[role="listbox"] {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  top: 85%;
  background-color: #080e20;
  border: solid 1px #ed46c7;
}

.suggestion {
  padding: 5px;
  width: 100%;
  color: #ed46c7;
  border: solid 1px #ed46c7;
  border-radius: 30px;
  margin-block: 10px;
}

.suggestion:hover {
  background-color: #ed46c7;
  color: #080e20;
  cursor: pointer;
}

.band-registration-form-genres {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
