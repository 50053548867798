.partners-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
  height: calc(100vh - 51px); /* 50px is the height of the footer bar */
  overflow-y: scroll;
}
.partners-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-top: 6%;
  padding: 20px;
  width: 25%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.partners-card:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.partners-card img {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 1/1;
}

.partners-card p {
  text-align: left;
}
