.home-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-radius: 10%;
  padding-inline: 3%;
  transition: 0.3s;
  text-decoration: none;
  margin-top: 1%;
}

.home-card-image {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  transition: 0.3s;
  aspect-ratio: 1/1;
  object-fit: cover;
  min-height: 350px;
}

.home-card-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  overflow: hidden;
  transition: 0.3s;
  position: relative;
}

.home-card-image-wrapper:hover {
  transform: scale(1.1);
}

.card-overlay-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  padding-left: 7%;
}

.card-overlay-text {
  background: linear-gradient(
    270deg,
    rgba(237, 70, 199, 1) 35%,
    rgba(65, 81, 184, 1) 100%
  );
  color: #fff;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
  font-weight: 700;
  font-size: 140%;
}

.home-card-text {
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  transition: 0.3s;
  margin-top: 0.3em;
  display: flex;
  justify-content: left;
  width: 90%;
}

.location-text {
  color: #ed46c7;
}

.home-card-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  transition: 0.3s;
  margin-top: 0.3em;
}

.home-card-secondary-text {
  text-align: left;
}
