.event-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  transition: 0.3s;
  margin-top: 10vh;
  width: 100%;
}

.event-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  width: 80%;
  height: 90%;
  display: flex;
  position: relative;
  align-items: center;
}

.event-wrapper .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  position: relative;
  padding: 10px;
}

.event-image {
  border-radius: 15px;
  height: 100%;
  position: relative;
  aspect-ratio: 1/1;
}

#save {
  position: absolute;
  bottom: 2vh;
  right: 5%;
  margin: 1vh;
  padding: 1vh;
  background: #080e20;
  color: #ed46c7;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  cursor: pointer;
}

#save svg {
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  fill: none;
}

#save:hover svg {
  fill: #ed46c7;
}

#saved {
  fill: #ed46c7 !important;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  font-size: 2.5rem;
  font-weight: bold;
  background: linear-gradient(270deg, #ed46c7 35%, #4151b8 100%);
  color: #fff;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
}

.event-tags {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 5vh;
  color: #ed46c7;
  font-size: 1.5rem;
  font-weight: bold;
}

.event-body {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1.3rem;
}

.price {
  margin: 10px;
  background-color: #ed46c7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 2px;
  padding-inline: 10px;
}

.event-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 1.5rem;
}

#buy {
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  padding-inline: 5%;
}

#buy:hover {
  background-color: #ed46c7;
}

#follow {
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
  border-color: #4151b8;
  color: #ffffff;
  padding-inline: 5%;
}

#follow:hover {
  background-color: #4151b8;
}

#close {
  position: absolute;
  top: 1.5vh;
  right: 3vh;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: lighter;
}

.event-date {
  height: 5vh;
  font-size: 1.3rem;
  font-weight: 500;
}

.event-location {
  height: 5vh;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  color: #ed46c7;
}

.event-location:hover {
  text-decoration: underline;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  width: 85%;
  border-radius: 5px;
  overflow: hidden;
}

.event-title {
  font-size: 2.3rem;
  max-width: 75%;
  text-align: left;
  background: linear-gradient(
    270deg,
    rgba(237, 70, 199, 1) 35%,
    rgba(65, 81, 184, 1) 100%
  );
  color: #fff;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
  padding-block: 2%;
  text-decoration: none;
}
