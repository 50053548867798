.messaging-page {
  width: 60vw;
  height: 78vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-top: 8vh;
}

.initial-message {
  font-size: 1.2rem;
  color: #ed46c7;
  margin-top: 2%;
  border: 1px solid #ed46c7;
  padding: 10px;
  border-radius: 20px;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  max-height: 80%;
}

.message {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 40%;
  width: fit-content;
  word-wrap: break-word;
  min-height: 1em;
}

.self-message {
  background-color: #ed46c7;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: fit-content;
  word-wrap: break-word;
  float: right;
  text-align: right;
  align-self: flex-end;
}

.partner-message {
  background-color: #080e20;
  color: #ed46c7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: fit-content;
  word-wrap: break-word;
  border: 1px solid #ed46c7;
  border-left: none;
  float: left;
  align-self: flex-start;
}

.message-input-wrapper {
  position: absolute;
  bottom: 2%;
  display: flex;
}

.message-input {
  width: 45vw;
  height: 5vh;
  border: 1px solid #ed46c7;
  border-radius: 20px;
  padding: 10px;
  margin-left: 2%;
}

.message-input:focus {
  outline: none;
}

.message-input-wrapper svg {
  width: 20px;
  height: 20px;
}

.message-input-wrapper button {
  padding-inline: 10px;
}

.messages {
  width: 100%;
  display: flex;
  flex-direction: column;
}
