.search-bar {
  display: flex;
  align-items: center;
  margin: 10px 0;
  border: 5px solid #ed46c7;
  border-radius: 50px;
  height: 7vh;
  margin-top: 2vh;
  width: 60%;
  position: relative;
  padding-inline: 1%;
}

.search-bar-label {
  font-weight: bold;
  display: flex;
  justify-content: left;
  font-size: 1.2rem;
}

.search-bar-input {
  color: #fff;
  border: none;
  background: none;
}

.search-bar-input::placeholder {
  color: #555555;
}

.search-bar-input:focus {
  outline: none;
}

.search-item {
  padding: 1%;
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.search-item-bordered::before {
  content: "";
  width: 1px;
  height: 60%;
  background-color: #ed46c7;
  position: absolute;
  top: 20%;
  right: 0;
}

.search-button {
  color: #fff;
  border: none;
  background: none;
  padding-top: 1%;
  border-radius: 150px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: -5px;
  margin: 0px;
  height: 110%;
}

.search-button path {
  fill: #ed46c7;
}

.search-button:hover {
  background-color: #ed46c7;
  transition: all 0.3s ease-in-out;
}

.search-button:hover path {
  fill: #fff;
}

.venue-suggestions {
  position: absolute;
  top: 117%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 500%;
  border: #ed46c7 solid 1px;
  border-radius: 15px;
  z-index: 1;
  overflow: auto;
  left: 0;
}

.venue-suggestion {
  border: none;
  border-bottom: #ed46c7 solid 1px;
  border-radius: 0;
  width: 100%;
  margin: auto;
  height: 20%;
}

.venue-suggestions-arrow {
  z-index: 2;
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ed46c7 transparent;
}

.search-item .react-datepicker__input-container {
  display: flex;
}

.genre-suggestion {
  border: none;
  border-bottom: #ed46c7 solid 1px;
  border-radius: 0;
  width: 100%;
  margin: auto;
  height: 20%;
}
