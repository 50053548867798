.venue-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transition: 0.3s;
}

.venue-registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  height: 90vh;
  margin-top: 10vh;
}

.venue-registration-form-label {
  font-weight: bold;
  display: flex;
  justify-content: left;
  font-size: 1.2rem;
}

.venue-registration-form-input {
  margin: 1vh 0;
  padding: 1vh;
  font-size: 1.2rem;
  background: #080e20;
  border: solid 1px #ed46c7;
  border-radius: 5px;
  color: #ed46c7;
}

.venue-registration-form-input::placeholder {
  color: #515151;
}

.venue-registration-form-input:focus {
  outline: none;
  background: #080e20;
}

.error-message {
  color: rgb(154, 0, 0);
  font-size: 1.2rem;
  margin: 1vh 0;
}

.venue-registration-form-detail {
  color: #ed46c7;
}

#description {
  padding-bottom: 5em;
  font-family: "Mulish", sans-serif;
  resize: none;
}
