.saved-thumbnail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  transition: 0.3s;
  margin-block: 2%;
  width: 100%;
}

.saved-thumbnail-image {
  border-radius: 15px;
  width: 20%;
  position: relative;
  aspect-ratio: 1/1;
  float: left;
}

.saved-thumbnail-image img {
  border-radius: 15px;
  height: 100%;
  position: relative;
  aspect-ratio: 1/1;
}

.saved-thumbnail-details {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  width: 70%;
  height: 100%;
  margin-left: 2%;
  margin-top: 3%;
  font-size: 1.3rem;
  color: #fff;
  text-align: right;
}

.saved-thumbnail-title {
  font-size: 2rem;
  font-weight: 700;
  width: 70%;
  margin-top: 1%;
  display: flex;
  justify-content: end;
}

.saved-thumbnail-desc {
  font-size: 1rem;
  color: #c7c7c7;
}

.saved-thumbnail-location {
  color: #ed46c7;
  margin-block: 1%;
}

.saved-thumbnail-tags {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1%;
}
