.checkout-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.checkout-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.checkout-desc {
  margin-block: 0.8rem;
}

#checkout-and-portal-button {
  font-size: 1.4rem;
  background: none;
  color: #fff;
  border-color: #4151b8;
  width: 100%;
}

#checkout-and-portal-button:hover {
  background-color: #4151b8;
}

#return {
  font-size: 1.4rem;
  background: none;
  color: #fff;
  width: 100%;
}

#return:hover {
  background-color: #ed46c7;
}
