.profile-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffffff;
  margin: auto;
  margin-top: 4vh;
  width: 95vw;
  transition: 0.3s;
}

.profile-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: 85vh;
  width: 85%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.profile-image {
  width: 47%;
  border-radius: 15px;
  margin: 3%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
}

.profile-info {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.profile-label {
  font-size: 1.5rem;
  margin: 10px 0;
  font-weight: bold;
}

#profile-logout {
  background: none;
  color: #ed46c7;
  border: 2px solid #ed46c7;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
}

#profile-logout:hover {
  background-color: #ed46c7;
  color: #ffffff;
}

#profile-delete {
  background-color: #980000;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
}

#profile-delete:hover {
  background-color: #ff0000;
}

#profile-stripe {
  background: none;
  color: #6772e5;
  border: 2px solid #6772e5;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
}

#profile-stripe:hover {
  background-color: #6772e5;
  color: #ffffff;
}
