.booking-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #080e20;
  width: 20vw;
  border-left: 1px solid #ed46c7;
  height: 100vh;
}

.booking-details-image-wrapper {
  width: 80%;
  aspect-ratio: 1/1;
}

.booking-details-image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: none;
}

.booking-details-title {
  font-size: 1.5rem;
  font-weight: 800;
  color: #e0e0e0;
  margin-top: 5%;
}

.booking-details-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 3%;
  text-align: left;
  width: 80%;
}

.booking-details-details {
  font-weight: 600;
  margin-top: 5%;
  font-size: 1.1rem;
}

.booking-details-date {
  margin-top: 3%;
}

.booking-details-price {
  margin-top: 3%;
}

.booking-details-status {
  margin-top: 3%;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  width: fit-content;
  text-transform: capitalize;
}

.booking-details-status-pending {
  background-color: #757575;
  color: #e0e0e0;
}

.booking-details-status-confirmed {
  background-color: #ed46c7;
  color: #e0e0e0;
}

.booking-details-status-cancelled {
  background-color: #8f0000;
  color: #e0e0e0;
}

.booking-details-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  margin-top: 4%;
}

.booking-details-button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 1%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.booking-cancel-button {
  background-color: #8f0000;
  color: #e0e0e0;
  border: none;
}

.booking-cancel-button:hover {
  background-color: #b71c1c;
}

.booking-confirm-button {
  color: #e0e0e0;
  border: 3px solid #4151b8;
}

.booking-confirm-button:hover {
  background-color: #4151b8;
}

#feature {
  width: 85%;
  margin-inline: auto;
  font-size: 1.1rem;
  border-color: #4151b8;
  color: #e0e0e0;
}

#feature:hover {
  background-color: #4151b8;
  cursor: pointer;
}
