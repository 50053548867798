.header {
  position: absolute;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  border-image: linear-gradient(90deg, #ed46c7 0%, #4151b8 75%) 100;
  border-width: 3px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 8vh;
  background-color: #080e20;
  right: 0;
  z-index: 100;
}

.header-logo-container {
  flex: 0 1 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-45%); /* adjust when we have a cropped logo */
}

.header-logo {
  width: 150px;
}

.header-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  border: #ed46c7 3px solid;
  border-radius: 15px;
  margin-right: 10px;
}

.header-nav-item {
  padding: 5px;
  padding-inline: 10px;
  font-size: 1.5rem;
}

.header-nav a {
  color: #ed46c7;
  text-decoration: none;
  height: 40px;
}

.header-nav-item svg {
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  fill: none;
}

.header-nav-item svg:hover {
  fill: #ed46c7;
}
